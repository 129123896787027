
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    TextGL,
    WhiteSpace,
    Input,
    Button,
    Hr,
    Icon,
    TextLinear,
    FlexBlock,
    Progress,
    Hot,
    Tooltip,
    APR,
    NoData
} from '../../components'


// import StakeSeed from './StakeSeed'
// import StakeDialog from '../StakeDialog'

import useBalance from '../../hook/pages/useBalance'

import {
    useStakeF1,
    useStakeF
} from '../../hook/pages/useStakeF'


// dialog 
import useDialog from '../../hook/useDialog'
import useInput from '../../hook/useInput'

// import useCopy from '../../hook/useCopy'

import { wFormatInt, splitNumber } from 'web3-sam/dist/BigNumber';
import {utils} from 'web3-sam'

import {getUrlParams} from '../../utils'

// import SvgCircle,{CircleArr} from '../../pageComponents/SvgCircle'
// 
// import {useSmiCountdown} from '../../hook/useCountdown'

import Approve from '../../contractComponents/Approve';

const { shortAddress } = utils


function StakeLp() {
    const bal = useBalance()
    const {f1} = useStakeF()
    const {
        stakeAmount,
        recommend,
        buttonUsdt,
        buttonLum,
        buttonAct
    } = useStakeF1()
    
    useEffect(() => {
        const {r} = getUrlParams()
        if (r) {
            recommend.onChange(r)
        }
    }, [])

    const {LMU, USDT} = bal.balance
    return (
        <Container>
            <Card>
                <TextM>LP Pool</TextM>
                <br />
                <TextSM color='2'>Staked USDT to earn FY</TextSM>
                <WhiteSpace />
                <Hr />
                <WhiteSpace />
                <TextSM style={{marginBottom: '6px'}} color='2'>Available <TextLinear size='0'>{wFormatInt(USDT)}</TextLinear> USDT · <TextLinear size='0'>{LMU}</TextLinear> LMU</TextSM>
                <br />
                <Input
                    input={stakeAmount}
                />
                {
                    !f1.hadRecommend &&
                    <>
                        <WhiteSpace />
                        <TextSM style={{marginBottom: '6px'}} color='2'>Recommender</TextSM>
                        <br />
                        <Input input={recommend}/>
                    </>
                }
                <WhiteSpace />
                <FlexBlock flex>
                    <TextSM color='2'>My Staked Value</TextSM>
                    <TextSM>{wFormatInt(f1.stakedValue)}</TextSM>
                </FlexBlock>
                <FlexBlock flex>
                    <TextSM color='2'>My Share</TextSM>
                    <TextSM>{wFormatInt(f1.share)}</TextSM>
                </FlexBlock>
                {
                    f1.waitActive * 1 > 0 &&
                    <FlexBlock style={{margin:'6px 0px 0px'}} flex>
                        <TextSM color='2'>Activable</TextSM>
                        <TextSM>{wFormatInt(f1.waitActive)} <Approve size='0' status='1' thin {...buttonAct} /> </TextSM>
                    </FlexBlock>
                }
                <WhiteSpace />
                <FlexBlock flex>
                    <Approve w='49' status='1' {...buttonUsdt} />
                    <Approve w='49' status='1' {...buttonLum} />
                </FlexBlock>
            </Card>
            <WhiteSpace />
            <Card>
                <TextM>My Team</TextM>
                <WhiteSpace />
                <Hr />
                <WhiteSpace />
                {   
                    f1.team.length === 0?
                    <NoData />:
                    f1.team.map(v => (
                        <div style={{marginBottom: '10px'}} key={v.id}>
                            <TextSM>{shortAddress(v.address, 8)}</TextSM>
                            <FlexBlock flex>
                                <TextSM color='2'>Staked USDT</TextSM>
                                <TextSM>{wFormatInt(v.stakeValue)}</TextSM>
                            </FlexBlock>
                            <WhiteSpace />
                            <Hr />
                        </div>
                        
                    ))
                }
            </Card>
        </Container>
    )
}
export default StakeLp