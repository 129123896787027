
import { memo } from 'react';

import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    TextGL,
    WhiteSpace,
    Input,
    Button,
    Hr,
    Icon,
    TextLinear,
    FlexBlock,
    ChooseButtons,
    // Progress,
    // Hot,
    // Tooltip,
    // APR,
    NoData
} from '../../components'


// import StakeSeed from './StakeSeed'
// import StakeDialog from '../StakeDialog'

import useBalance from '../../hook/pages/useBalance'


// dialog 
import useDialog from '../../hook/useDialog'
import useInput from '../../hook/useInput'

// import useCopy from '../../hook/useCopy'

import { wFormatInt, splitNumber } from 'web3-sam/dist/BigNumber';
import {utils} from 'web3-sam'

// import SvgCircle,{CircleArr} from '../../pageComponents/SvgCircle'

import {useStakeF2, useStakeF} from '../../hook/pages/useStakeF' 

import {useSmiCountdown} from '../../hook/useCountdown'

import Approve from '../../contractComponents/Approve';

const { shortAddress } = utils


function StakeFy() {
    const bal = useBalance()
    
    const {f2} = useStakeF()
    // console.log({f2}, 'f')
    const {
        stakeAmount,
        stakeTime,
        button
    } =  useStakeF2()

    return (
        <Container>
            <Card>
                <TextM>FY Pool</TextM>
                <br />
                <TextSM color='2'>Staked FY to earn FY</TextSM>
                <WhiteSpace />
                <Hr />
                <WhiteSpace />
                <TextSM style={{marginBottom: '6px'}} color='2'>Available <TextLinear size='0'>{wFormatInt(bal.balance.FY)}</TextLinear> FY</TextSM>
                <br />
                <Input input={stakeAmount}/>
                <WhiteSpace />
                <TextSM style={{marginBottom: '6px'}} color='2'>Stake Days</TextSM>
                <br />
                <ChooseButtons
                    size='0'
                    list={[
                        {id:30, title: '30D'},
                        {id:90, title: '90D'},
                        {id:180, title: '180D'},
                        {id:360, title: '360D'},
                    ]}
                    {...stakeTime}
                />
                <WhiteSpace />
                <FlexBlock flex>
                    <TextSM color='2'>My Staked FY</TextSM>
                    <TextSM>{wFormatInt(f2.stakedFY)}</TextSM>
                </FlexBlock>
                <FlexBlock flex>
                    <TextSM color='2'>My Stock</TextSM>
                    <TextSM>{wFormatInt(f2.stakedStock)}</TextSM>
                </FlexBlock>
                <FlexBlock flex>
                    <TextSM color='2'>My Stock 360</TextSM>
                    <TextSM>{wFormatInt(f2.stock360)}</TextSM>
                </FlexBlock>
                <WhiteSpace />
                <Approve w='100' status='2' {...button} />
            </Card>
            <WhiteSpace />
            <Card>
                <TextM>My Position</TextM>
                <WhiteSpace />
                <Hr />
                <WhiteSpace />
                {
                    f2.positioin.length === 0 ?
                    <NoData />:
                    f2.positioin.map(v => (
                        <div style={{marginBottom: '10px'}} key={v.id}>
                            <FlexBlock flex>
                                <TextSM color='2'>Staked FY</TextSM>
                                <TextSM>{wFormatInt(v.stakedFy)}</TextSM>
                            </FlexBlock>
                            <FlexBlock flex>
                                <TextSM color='2'>Staked Days</TextSM>
                                <TextSM>{v.stakeDays}</TextSM>
                            </FlexBlock>
                            <FlexBlock flex>
                                <TextSM color='2'>Start Time</TextSM>
                                <TextSM>{v.startTime}</TextSM>
                            </FlexBlock>
                            <WhiteSpace />
                            <Hr />
                        </div>
                        
                    ))
                }
            </Card>
        </Container>
    )
}
export default StakeFy