
import { memo } from 'react';

// import { useTranslation } from 'react-i18next';

// import styled from 'styled-components';

import {
    // HashRouter as Router,
    // Route,
    // Link,
    useHistory
} from "react-router-dom";

import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    TextGL,
    WhiteSpace,
    // Input,
    Button,
    Hr,
    Icon,
    // TextLinear,
    FlexBlock,
    // Progress,
    // Hot,
    Tooltip,
    // APR,
    // NoData
} from '../../components'


// import StakeSeed from './StakeSeed'
// import StakeDialog from '../StakeDialog'
import Swap from '../Swap'

import useBalance from '../../hook/pages/useBalance'


// dialog 
import useDialog from '../../hook/useDialog'
import useInput from '../../hook/useInput'

// import useCopy from '../../hook/useCopy'

import { wFormatInt, splitNumber } from 'web3-sam/dist/BigNumber';
import {utils} from 'web3-sam'

// import SvgCircle,{CircleArr} from '../../pageComponents/SvgCircle'

import {useSmiCountdown} from '../../hook/useCountdown'

import {
    useStakeF,
    useClaimF2,
    useClaimF1
} from '../../hook/pages/useStakeF'

// const { shortAddress } = utils

function ClaimF1() {
    return <Button size='0' {...useClaimF1()}/>
}
const ClaimF1Mome = memo(ClaimF1)

function ClaimF2() {
    return <Button size='0' {...useClaimF2()}/>
}
const ClaimF2Mome = memo(ClaimF2)


function Home() {
    const bal = useBalance()
    const {open} = useDialog(<Swap />)
    const {f1, f2, copyLink} = useStakeF()
    // console.log(
    //     bal.oracle(["SEED","USDT", "GAS"]),
    //     bal.getOutAmount(1, ["SEE","USDT", "GAS"])
    // )
    // console.log(
    //     bal.oracle(["FY","USDT"]),
    // )
    const oracle = bal.oracle(["FY","USDT"])
    // const copyLink = copyLink
    const {integer, decimalTail, decimal0} = splitNumber(oracle.price)

    const history = useHistory();

    function goLp() {
        history.push("/stake");
    }

    function goFy() {
        history.push("/stakefy");
    }

    return (
        <Container>
            <FlexBlock flex>
                <div decorate='1' style={{textAlign: 'left'}}>
                    <Tooltip
                        title={
                            <>
                                {/* <TextSM>{seeOrcal.pricePath[1]} {wFormatInt(seeOrcal.lpRs[0])}</TextSM> */}
                                <TextSM style={{marginBottom: "6px"}}>FY {wFormatInt(oracle.lp[0][0])}</TextSM>
                                <br />
                                {/* <TextSM>{seeOrcal.pricePath[0]} {wFormatInt(seeOrcal.lpRs[1])}</TextSM> */}
                                <TextSM>USDT {wFormatInt(oracle.lp[0][1])}</TextSM>
                            </>
                        }
                        placement="right"
                        arrow
                    >
                        <TextSM color='2'>FY / USDT <Icon size='12' type='icon-info-fill' /></TextSM>
                    </Tooltip> 
                    <br />
                    <TextGL>{wFormatInt(integer)}.{decimal0 !== 0 ? <sup>{decimal0}</sup> : null }{(decimalTail.slice(0,4)*1)}</TextGL>
                </div>
                <div>
                    <Button style={{marginRight:'8px'}} status='1' size='0' onClick={copyLink}>Share Link</Button>
                    <Button status='2' size='0' onClick={open}>Buy FY</Button>
                </div>
            </FlexBlock>
            <WhiteSpace />
            <Card>
                <TextM>LP Pool</TextM>
                <br />
                <TextSM color='2'>Staked USDT to earn FY</TextSM>
                <WhiteSpace />
                <Hr />
                <WhiteSpace />
                <FlexBlock flex>
                    <div style={{textAlign:'left'}}>
                        {/* <Tooltip
                            title={<Introducer pId={history.pid} />}
                            placement="bottom"
                            arrow
                        >
                            <TextSM color='2'>Available Rewarded <Icon size='12' type='icon-info-fill' /></TextSM>
                        </Tooltip> */}
                        <TextSM color='2'>Available Rewarded</TextSM>
                        <br />
                        <TextMD>{f1.claim} <TextSM color='2'>FY</TextSM></TextMD>
                    </div>
                    {/* <ClaimButton
                        rewardSDD={history.rewardSDD}
                        isShare={isShare}
                        proveidId={proveidId}
                        pid={history.pid}
                        endTime={history.endTime}
                    /> */}
                    <ClaimF1Mome />
                </FlexBlock>
                <WhiteSpace />
                <Hr />
                <WhiteSpace />
                <FlexBlock flex>
                    <TextSM color='2'>My Staked Value</TextSM>
                    <TextSM>{wFormatInt(f1.stakedValue)}</TextSM>
                </FlexBlock>
                <FlexBlock flex>
                    <TextSM color='2'>My Share</TextSM>
                    <TextSM>{wFormatInt(f1.share)}</TextSM>
                </FlexBlock>
                <WhiteSpace />
                <Button w='100' status='2' onClick={goLp}>Stake Lp</Button>
            </Card>
            <WhiteSpace />
            <Card>
                <TextM>FY Pool</TextM>
                <br />
                <TextSM color='2'>Staked FY to earn FY</TextSM>
                <WhiteSpace />
                <Hr />
                <WhiteSpace />
                <FlexBlock flex>
                    <div style={{textAlign:'left'}}>
                        <TextSM color='2'>Available Rewarded</TextSM>
                        <br />
                        <TextMD>{f2.claim} <TextSM color='2'>FY</TextSM></TextMD>
                        <br />
                        <TextSM color='2'>Claim 360: <TextSM>{f2.claim360}</TextSM></TextSM>
                        <br />
                        <TextSM color='2'>Claim Base: <TextSM>{f2.claimBase}</TextSM></TextSM>
                    </div>
                    {/* <ClaimButton
                        rewardSDD={history.rewardSDD}
                        isShare={isShare}
                        proveidId={proveidId}
                        pid={history.pid}
                        endTime={history.endTime}
                    /> */}
                    <ClaimF2Mome />
                </FlexBlock>
                <WhiteSpace />
                <Hr />
                <WhiteSpace />
                <FlexBlock flex>
                    <TextSM color='2'>My Staked FY</TextSM>
                    <TextSM>{wFormatInt(f2.stakedFY)}</TextSM>
                </FlexBlock>
                <FlexBlock flex>
                    <TextSM color='2'>My Stock</TextSM>
                    <TextSM>{wFormatInt(f2.stakedStock)}</TextSM>
                </FlexBlock>
                <FlexBlock flex>
                    <TextSM color='2'>My Stock 360</TextSM>
                    <TextSM>{wFormatInt(f2.stock360)}</TextSM>
                </FlexBlock>
                <WhiteSpace />
                <Button w='100' status='1' onClick={goFy}>
                    Satke FY
                </Button>
            </Card>
        </Container>
    )
}
export default Home