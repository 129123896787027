
import { useEffect, useState, useMemo,memo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';

import {
    Button,
    // FlexBlock,
    Icon,
    Card,
    TextGL,
    TextMD,
    TextM,
    TextSM,
    // TitleText,
    WhiteSpace,
    // WalletIcon,
    FlexBlock,
    CheckButton,
    TextLinear,
    Hr,
    NoData
} from '../../components'


// import useDrawer from '../../hook/useDrawer'

import {useWeb3, utils} from '../../web3'

import { useTransactionList } from '../../hook/useTransaction'

import {TxSummaryInit, ChainConfig} from '../../contract/chainConfig'

const { shortAddress } = utils

// 状态
// 1. 未连接钱包
// 2. 连接钱包
// 2.1 有交易记录
// 2.2 无交易记录
// 2.3 有 pending 交易记录

// icon-queren
// icon-cuowu1
// icon-icon-test
function checkTxStatus(receipt) {
    if ( !receipt ) return 'icon-icon-wrong'
    return receipt.status ? 'icon-chenggong' : 'icon-cuowu1'
}

function TransactionDetail() {
    const list = useTransactionList()
    console.log(
        list,"listlist"
    )
    const {
        // goToAddress,
        goToTxHash,
        summary,
    } = useMemo(() => {
        const config = ChainConfig()
        const goToAddress = config.addressPath
        const goToTxHash = config.txPath
        const txSummary = TxSummaryInit()

        const summary = (summaryObjc) => {
            // config.log()
            const { name } = summaryObjc
            const parseFun = txSummary[name.toLocaleLowerCase()]
            if (parseFun) {
                return parseFun(summaryObjc)
            } else return name
        }
        return {
            goToAddress,
            goToTxHash,
            summary,
        }
    }, [])

    return (
        <Card decorate='8'>
            <WhiteSpace size='3'/>
            <TextMD>Transactions</TextMD>
            <WhiteSpace />
            <Hr />
            {/* <WhiteSpace /> */}
            {
                list.length === 0 ? <NoData /> :
                list.slice(0,6).map((item) => (
                    <a href={goToTxHash(item.hash)} key={item.hash} target="_blank">
                        <TxBlock >
                            <div style={{width: '36px'}}>
                                <Icon size='18' type={checkTxStatus(item.receipt)}/>
                            </div>
                            {/* {checkTxStatus(item)} */}
                            <div style={{flex: 4}}>
                                <TextSM b='800'>{item.summary.con ? item.summary.con : summary(item.summary)}</TextSM>
                                <br />
                                <TextSM b='800'>Hash: <TextSM color='2'>{shortAddress(item.hash, 10)}</TextSM></TextSM>
                                <br />
                                <TextSM b='800'>Created: <TextSM color='2'>{item.creatDate}</TextSM></TextSM>
                            </div>
                        </TxBlock>
                    </a>
                ))
            }
        </Card>
    )
}

export default memo(TransactionDetail)


const TxBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    background-color: rgb(217 236 205 / 27%);
    margin-top: 12px;
    border-radius: 6px;
`