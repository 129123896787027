
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';
import translationZH from './locales/zh/translation.json';

import translation from './locales/translation.json';

const resources = {
  en: { translation: {} },
  zh: { translation: {} },
  yue: { translation: {} },
  maly: { translation: {} },
  jp: { translation: {} },
  kr: { translation: {} }
};

translation.forEach(item => {
  resources.en.translation[item[0]] = item[0];
  resources.zh.translation[item[0]] = item[1];
  resources.yue.translation[item[0]] = item[2];
  resources.maly.translation[item[0]] = item[3];
  resources.jp.translation[item[0]] = item[4];
  resources.kr.translation[item[0]] = item[5];
})

console.log(resources);

i18n
  // load translation using http -> see /public/locales
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: "en",
    // fallbackLng: "en",

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;