export const inPc = (() => {
    var sUserAgent = navigator.userAgent;
    if (sUserAgent.indexOf('Android') > -1 || sUserAgent.indexOf('iPhone') > -1 || sUserAgent.indexOf('iPad') > -1 || sUserAgent.indexOf('iPod') > -1 || sUserAgent.indexOf('Symbian') > -1) {
        return false
    } else {
        return true
    }
})();

function linearGradient(deg, start, stop) {
    return `linear-gradient(${deg}deg,  ${start}, ${stop})`
}

// function shadow(color) {
//     return `0px 0px .4rem ${color}`
// }


// card
// block input
// text
// button
// alert
// draw

// size
// background
// border

const background = [
    {
        // #7fc248,#63bb35
        background: 'linear-gradient(45deg,rgb(252 255 244),rgb(255 247 238 / 87%))',
        // backgroundImage: 'url(./.png)',
        // border: '1rem solid transparent',
    },
    {
        // #7fc248,#63bb35
        background: 'linear-gradient(45deg,rgb(255 255 255 / 78%),rgb(218 255 229 / 80%))'
    },
    {
        background: 'linear-gradient(45deg,rgb(254 232 178 / 78%),rgb(255 188 188 / 78%))'
    },
    { 
        background: `#72c9b5`
    },
    {
        background: '#e2e789'
    },
    {
        background: '#99d172'
    },
    {
        background: `#e8edc8`,
        border: '0.1rem solid transparent',
        backgroundClip: 'padding-box, border-box',
        backgroundOrigin: 'padding-box, border-box'
    },
    {
        background: `linear-gradient(136deg,#fee8b2,#ffbcbc)`,
        border: '0.1rem solid transparent',
        backgroundClip: 'padding-box, border-box',
        backgroundOrigin: 'padding-box, border-box'
    },
    {
        // #7fc248,#63bb35
        background: 'linear-gradient(45deg,rgb(252 255 244 ),rgb(255 247 238 ))',
        // backgroundImage: 'url(./.png)',
        // border: '1rem solid transparent',
    }
]

const color = [
    '#fbf6ee',
    // '#f0af4b',
    '#688eb6',
    '#aeafb9',
    '#838383',
    '#94843a',
    '#d37c7c',
    '#2f3119'
].reverse();

const size = new Array(7).fill(1.2).map((v,i) => v * 1.382**i);




const theme = {
    // public
    MAX_WIDTH: 1080,
    isPc: inPc,
    isV2: window.location.hostname.split(".")[0] !== 'v3',
    bodyBack: 'url(./back.png)',
    tableBackground: '#f2f2f2',
    footerHeight: '5.2rem',
    borderRadius: '18px',
    boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.1);',
    active: '#F3503C',
    testLinear: `background: linear-gradient(120deg,#f79721,#d1811d,#e28e25)`,
    headBarBackground: '#c4e3c2',
    // font
    size,
    padding: size.map(v => `${v * 0.618}rem ${v}rem`),
    // 由亮 到 暗
    // 字体颜色
    color,
    buttonSize: [
        // small
        {
            fontSize: '1rem',
            // borderRadius: '0rem',
        },
        {
            fontSize: '1.4rem',
            padding: '1rem 1.4rem',
        },
        {
            fontSize: '1.8rem',
            padding: '1.4rem 1.8rem',
        },
        {
            fontSize: '2.6rem',
            padding: '1.4rem 1.8rem',
        },
        {
            fontSize: '3rem',
            padding: '1.4rem 1.8rem',
        },
    ],
    // 由 亮 变 暗
    block: [
        background[0],
        background[1],
        background[2],
        background[3],
        background[4],
        background[5],
        background[6],
        background[7],
        background[8],
    ],
    draw: background[2],
    // 由 亮 变 暗
    button: [
        // normal
        {
            ...background[4],
            color: color[0]
        },
        // normalA
        {
            ...background[5],
            color: color[0]
        },
        // normalB
        {
            ...background[3],
            color: color[0]
        },
        // border
        {
            ...background[6],
            color: color[0]
        },
        // disabled
        {
            background: '#dcdcdc',
            color: '#51524c !important',
            // border: '1px solid #565656'
        },
        // active
        {
            ...background[7],
            color: color[0]
        },
        // un active
        {
            ...background[6],
            color: color[0]
        },
        // ...other
    ],
    squareDot: [
        background[8],
        background[9]
    ]
}

export default theme