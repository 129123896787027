
import { useEffect, useState } from 'react'
// import styled from 'styled-components'
// import { useTranslation } from 'react-i18next';

// import {
//     Button,
//     // FlexBlock,
//     Icon,
//     Card,
//     // TextGL,
//     TextMD,
//     // TextM,
//     TextSM,
//     // TitleText,
//     WhiteSpace,
//     // WalletIcon,
//     FlexBlock,
//     CheckButton,
//     TextLinear
// } from '../../components'


// import useDrawer from '../../hook/useDrawer'
// import useDrawer from '../../hook/useDrawer'

import {useWeb3, initWeb3} from '../web3'
import GetTxStorage from '../web3/transferLoca'

export function useTransactionPendingCount() {
    const {account} = useWeb3()
    const [peindCount, setPeindCount] = useState(0)
    useEffect(() => {
        const {
            getPendingTxHash,
            on,
            remove,
        } = GetTxStorage()
        const getTx = () => {
            setPeindCount(getPendingTxHash().length)
        }
        getTx()
        on(getTx)
        return () => {
            remove(getTx)
        }
    }, [account])
    return peindCount
}

export function useTransactionList() {
    const {account} = useWeb3()
    const [txList, setTxList] = useState([])
    useEffect(() => {
        const {
            on,
            remove,
            getAll
        } = GetTxStorage()
        const getTx = () => {
            const txObj = getAll()
            setTxList(
                Object.keys(txObj).map(key => ({
                    ...txObj[key],
                    creatDate: (new Date(txObj[key].creatTime * 1000)).Format('yyyy-MM-dd hh:mm:ss'),
                    hash: key
                })).sort((a, b) => b.creatTime - a.creatTime)
            )
        }
        getTx()
        on(getTx)
        return () => {
            remove(getTx)
        }
    }, [account])
    return txList
}

// // 状态
// // 1. 未连接钱包
// // 2. 连接钱包
// // 2.1 有交易记录
// // 2.2 无交易记录
// // 2.3 有 pending 交易记录
// function TransactionDetail() {
//     return (
//         <div>
//             <div>TransactionDetail</div>
//         </div>
//     )
// }

// export default TransactionDetail