
import {newContract} from "../web3";
import {getConstants} from './constants';

import I_LP from './ABI/LP.json'
import IFatory from './ABI/IFactory.json'

////// customize dapp ABI //////
import I_F1 from './ABI/F1.json'
import I_F2 from './ABI/F2.json'
import I_STOCK from './ABI/Stock.json'

////// Native token //////
export const EthAddr = () => getConstants().ETH
export const WEthAddr = () => getConstants().WETH
export const Factorys = () => getConstants().ORACLE_FACTORYS
export const Oracle = () => getConstants().ORACLE
////// uesBalance //////
export const TokenAll = () => {
    const {
        BALANCE_LIST
    } = getConstants()
    const map = {}
    BALANCE_LIST.forEach(v => {
        map[v[2]] = v
    })
    return {
        list: BALANCE_LIST,
        map
    }
}


export const ChooseList = () => getConstants().BALANCE_LIST

// lp
export const Pair = address => newContract(address, I_LP)
export const Factory = address => newContract(address, IFatory)
////// token end //////

///// stake /////
// export const Stake = () => newContract(getConstants().STAKE, I_STAKE)
export const F1 = () => newContract(getConstants().F1, I_F1)
export const F2 = () => newContract(getConstants().F2, I_F2)
export const Stock = () => newContract(getConstants().STOCK, I_STOCK)
export const Stock360 = () => newContract(getConstants().STOCK360, I_STOCK)