import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
    Button,
    TextSM,
    Card,
    PureButton,
    TextMD,
    WhiteSpace,
    Hr,
    FlexBlock
    // WingBlank
} from '../../components'

import {useDialog1} from "../../hook/useDialog"

// en: { translation: {} },
// zh: { translation: {} },
// yue: { translation: {} },
// maly: { translation: {} },
// jp: { translation: {} },
// kr: { translation: {} }
const T_List = {
    en: {
        flag: "en",
        text: "En"
    },
    zh: {
        flag: "China",
        text: "繁体"
    },
    yue: {
        flag: "Vietnam",
        text: "Tiếng Việt"
    },
    maly: {
        flag: "Malaysia",
        text: "Melayu"
    },
    jp: {
        flag: "Japan",
        text: "日本"
    },
    kr: {
        flag: "Korea",
        text: "한국인"
    }
}

function ChooseTranslation({onChange = () => {}}) {
    return (
        <Card decorate='8'>
            {/* <TextMD>Choose Language</TextMD>
            <WhiteSpace />
            <Hr />
            <WhiteSpace /> */}
            <FlexBlock style={{flexWrap: 'wrap'}} flex>
                {
                    Object.keys(T_List).map(k => {
                        const {flag, text} = T_List[k];
                        return (
                            <ChooseB>
                                <PureButton key={flag} onClick={() => onChange(k)}>
                                    <Flag src={`./flag/${flag}.png`}/>
                                    <TextSM style={{marginLeft: '8px', display:'block'}}>{text}</TextSM>
                                </PureButton>
                            </ChooseB>
                        )
                    })
                }
            </FlexBlock>
        </Card>
    )
}

function TranslationButton() {
  const { t, i18n } = useTranslation();
    const [flag, setFlag] = useState("en")
    const {open} = useDialog1()
    const onClick = () => {
        open(
            <ChooseTranslation
                value={flag}
                onChange={la => {
                    i18n.changeLanguage(la);
                    setFlag(la)
                }}
            />
        )
    }
    return (
        <PureButton onClick={onClick} style={{marginLeft: "8px"}} empty>
            <Flag src={`./flag/${T_List[flag].flag}.png`}/>
        </PureButton>
    )
}

export default TranslationButton

const Flag = styled.img`
    width: 2.6rem;
    padding: 4px;
    border-radius: 1000px;
    background: #c4d105;
`

const ChooseB = styled.div`
    background: #f2f3df;
    display: block;
    width: 32%;
    padding: 4px;
    margin: 4px 0px;
    border-radius: 1000px;
`