
import { memo } from 'react';

import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    TextGL,
    WhiteSpace,
    Input,
    Button,
    Hr,
    Icon,
    TextLinear,
    FlexBlock,
    Progress,
    Hot,
    Tooltip,
    APR,
    NoData
} from '../../components'


// import StakeSeed from './StakeSeed'
// import StakeDialog from '../StakeDialog'

import useBalance from '../../hook/pages/useBalance'


// dialog 
import useDialog from '../../hook/useDialog'
import useInput from '../../hook/useInput'

// import useCopy from '../../hook/useCopy'

import { wFormatInt, splitNumber } from 'web3-sam/dist/BigNumber';
import {utils} from 'web3-sam'

// import SvgCircle,{CircleArr} from '../../pageComponents/SvgCircle'

import {useSmiCountdown} from '../../hook/useCountdown'

import {usePurchase} from '../../hook/pages/useStakeF'

import Approve from '../../contractComponents/Approve';

const { shortAddress } = utils


function PurchaseLMU() {
    const bal = useBalance()

    const {
        purchaseAmount,
        button
    } = usePurchase()
    
    // console.log(
    //     bal.oracle(["SEED","USDT", "GAS"]),
    //     bal.getOutAmount(1, ["SEE","USDT", "GAS"])
    // )
    // const integer = 12
    // const decimal0 = 2
    // const decimalTail = '1231'
    // const copyLink = () => {}
    return (
        <Container>
            <Card>
                <TextM>Purchase LMU</TextM>
                <br />
                <TextSM color='2'>1 USDT = 1 LMU</TextSM>
                <WhiteSpace />
                <Hr />
                <WhiteSpace />
                <TextSM color='2'>Available <TextLinear size='0'>{wFormatInt(bal.balance.USDT)}</TextLinear> USDT</TextSM>
                <br />
                <Input input={purchaseAmount}/>
                <WhiteSpace />
                <Approve w='100' status='1' {...button} />
            </Card>
        </Container>
    )
}
export default PurchaseLMU