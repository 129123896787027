// Relation
import { useEffect, useMemo, useState } from "react";

// import copy from 'copy-to-clipboard'

import {
    TokenAll,
    F1,
    F2,
    Stock,
    Stock360,
} from "../../contract/contract";

import { useWeb3, initWeb3, BN, multiCalls, SendOn, utils, ZERO_ADDRESS , SendLocalOn} from '../../web3'

import initAsyncData from '../initAsyncData'

import useInput from '../useInput'
import useButton from "./useButton"

import useCopy from '../useCopy'
// import { ca, tr } from "date-fns/locale";

// import {getUrlParams, debounce} from "../../utils"

const INIT_DATA = {
    f1: {
        totalShare: 0,
        totalStakedValue: 0,
        stakedValue: 0,
        stakedDeposit: 0,
        share: 0,
        timeMul: 0,
        claim: 0,
        waitActive: 0,
        recommend: ZERO_ADDRESS,
        hadRecommend: false,
        totalTeam: 0,
        team: []
    },
    f2: {
        stakedFY: 0,
        stakedStock: 0,
        stock360: 0,
        claim: 0,
        claim360: 0,
        claimBase: 0,
        positioin: []
    }
}

async function getF1Data(account) {
    const f1 = F1()
    const f2 = F2()
    const stock = Stock()
    const stock360 = Stock360()

    async function con(method) {
        console.log(
            await method.call()
        )
    }

    // con(
    //     f1.methods.introducer("0x1c935510F81aDB29319543cE7e4d9918e877be75")
    // )
    const calls = await multiCalls({
        // u1: f1.methods.userInfo(account),
        
        f2: {
            stock: stock.methods.claimFor(account),
            stock360: stock360.methods.claimFor(account),
            deposits: stock.methods.stockOf(account),
            deposits360: stock360.methods.stockOf(account),
            claim: f2.methods.claimFor(account),
            share360: f2.methods.stock360Balance(account),
            totalStock: stock.methods.totalStock()
        },
        f1: {
            timeMul: f1.methods.blockTimeMulE8(),
            recommend: f1.methods.introducer(account),
            claim: f1.methods.claimFor(account),
            userInfo: f1.methods.userInfo(account),
            introduersOf: f1.methods.introduersOf(account),
            totalStakedValue: f1.methods.totalStakedValue(),
            totalShare: f1.methods.totalShare(),
            waitActiveBalance: f1.methods.waitActiveBalance(account),
        }
    })
    

    // dep.rewardedFy - _dep.claimedFy;
    // _dep.claimedFy = _dep.rewardedFy;
    console.log(calls.f2,'calls')
    // console.table({
    //     u2Deposit: BN(calls.f2.deposits.rewardedFy).div(1e18).toString(10),
    //     u2Claim: BN(calls.f2.deposits.claimedFy).div(1e18).toString(10),
    //     u2Deposit1: BN(calls.f2.deposits1.rewardedFy).div(1e18).toString(10),
    //     u2Claim1: BN(calls.f2.deposits1.claimedFy).div(1e18).toString(10),
    // })
    // console.table({
    //     u2DepositFy: BN(calls.f2.rewardedFy).div(1e18).toString(10),
    //     u2ShareFy: BN(calls.f2.rewardedShareFy).div(1e18).toString(10),
    //     u2ClaimedFy: BN(calls.u1.claimedFy).div(1e18).toString(10),
    //     u1TotalReward: BN(calls.u1.rewardedShareFy).add(calls.u1.rewardedDepositFy).div(1e18).toString(10),
    //     rewardedDepositFy: BN(calls.f1.userInfo.rewardedDepositFy).div(1e18).toString(10),
    //     rewardedShareFy: BN(calls.f1.userInfo.rewardedShareFy).div(1e18).toString(10),
    //     totalReward: BN(calls.f1.userInfo.rewardedShareFy).add(calls.f1.userInfo.rewardedDepositFy).div(1e18).toString(10),
    //     claimedFy: BN(calls.f1.userInfo.claimedFy).div(1e18).toString(10),
    // })
    // userInfo.rewardedDepositFy + _userInfo.rewardedShareFy;
    // // console.log("_newReward %s , _userInfo.claimedFy %s", _newReward, _userInfo.claimedFy);
    // _reward = _newReward - _userInfo.claimedFy;


    const f1Data = {}
    const timeMul = BN(calls.f1.timeMul).div(1e8).toString(10)
    f1Data.timeMul = timeMul

    f1Data.stakedDeposit = BN(calls.f1.userInfo.stakeValue).div(1e18).toString(10)
    f1Data.stakedValue = BN(calls.f1.userInfo.stakeDeposit).div(timeMul).div(1e18).toString(10)
    // f1Data.share = BN(calls.f1.userInfo.share).div(timeMul).div(1e18).toString(10)
    f1Data.share = BN(calls.f1.userInfo.share).div(timeMul).div(1e18).toString(10)
    f1Data.claim = BN(calls.f1.claim).div(1e18).dp(6,1).toString(10)
    f1Data.waitActive = BN(calls.f1.waitActiveBalance).div(1e18).toString(10)
    console.log(f1Data.waitActive,'waitActive')
    f1Data.recommend = calls.f1.recommend
    f1Data.hadRecommend = calls.f1.recommend !== ZERO_ADDRESS
    f1Data.totalShare = BN(calls.f1.totalShare).div(timeMul).div(1e18).toString(10)
    f1Data.totalStakedValue = BN(calls.f1.totalStakedValue).div(1e18).toString(10)

    let totalTeam = BN(0)
    f1Data.team = calls.f1.introduersOf.infos.map((v,i) => {
        const stakeValue = v.stakeValue
        totalTeam = totalTeam.add(stakeValue)
        return {
            id: i,
            address: calls.f1.introduersOf.intros[i],
            stakeValue: BN(stakeValue).div(1e18).toString(10),
        }
    })
    f1Data.totalTeam = BN(totalTeam).div(1e18).toString(10)

    const f2Data = {}
   
    f2Data.stakedStock = BN(calls.f2.deposits.stock).div(1e18).toString(10)
    f2Data.stock360 = BN(calls.f2.deposits360.stock).div(1e18).toString(10)
    
    f2Data.claim = BN(calls.f2.claim.reward).add(calls.f2.stock).add(calls.f2.stock360).div(1e18).dp(6,1).toString(10)

    let f2TotalFY = BN(0)
    f2Data.positioin = calls.f2.claim.pos.map((v,i) => {
        const stakedFy = v.stakedFy
        f2TotalFY = f2TotalFY.add(stakedFy)
        const stakeDays = Math.floor((v.endTime - v.starTime) / 86400)
        return {
            id: i,
            stakedFy: BN(stakedFy).div(1e18).toString(10),
            stakeDays,
            startTime: new Date(v.starTime * 1000).toLocaleString()
        }
    }).sort((a,b) => b.id - a.id)

    f2Data.stakedFY = BN(f2TotalFY).div(1e18).toString(10)

    f2Data.claim360 = BN(calls.f2.stock360).div(1e18).dp(6,1).toString(10)
    f2Data.claimBase = BN(calls.f2.stock).div(1e18).dp(6,1).toString(10)
    // stakedStock

    return {
        f1: f1Data,
        f2: f2Data
    }
}


export function useStakeF() {
    const {account, getBlockNumber} = useWeb3()
    const [data, setData] = useState(INIT_DATA)
    const blockNubmer = getBlockNumber()

    useEffect(() => {
        // console.log({account, blockNubmer})
        initAsyncData(() => getF1Data(account), setData)
    },[account, blockNubmer])
    const copy = useCopy()
    const copyLink = () => {
        // copy(data.link)
        copy(
            window.location.origin + '/#/stake?r=' + account
        )
    }
    return {
        ...data,
        copyLink
    }
}

export function useStakeF1() {
    // const usdtAmount = useInput('', {teyp: 'number'})
    // const lumAmount = useInput('', {teyp: 'number'})
    const stakeAmount = useInput('', {teyp: 'number'})
    const recommend = useInput('')

    const {
        coins,
        sender
    } = useMemo(() => {
        const sender = F1()._address
        const tokenAll = TokenAll()
        return {
            coins:[[...tokenAll.map.USDT, 10000, true]],
            sender
        }
    }, [])

    // type 1 usdt 2 lum
    const send = (type = 1) => {
        const f1 = F1()
        // const usdt = usdtAmount.value
        // const lum = lumAmount.value
        const stakeAmountValue = stakeAmount.value
        let recommendAddress = recommend.value
        const stakeAmountWei = BN(stakeAmountValue).mul(1e18).dp(0,1).toString(10)
        // const lumWei = BN(lum).mul(1e18).dp(0,1).toString(10)
        if (recommendAddress === '') {
            recommendAddress = ZERO_ADDRESS
        }
        return type === 1 ? f1.methods.stake(stakeAmountWei, 0, recommendAddress) : f1.methods.stake(0, stakeAmountWei, recommendAddress)
    }

    const approve = {
        coins,
        sender,
    }
    const buttonUsdt = useButton('Stake USDT', {
        send: () => send(1),
        approve
    })
    const buttonLum = useButton('Stake LMU', {
        send: () => send(2),
        approve
    })

    const buttonAct = useButton('Act', {
        send: () => {
            const f1 = F1()
            let recommendAddress = recommend.value
            if (recommendAddress === '') {
                recommendAddress = ZERO_ADDRESS
            }
            return f1.methods.active(recommendAddress)
        },
        approve
    })

    return {
        stakeAmount,
        recommend,
        buttonUsdt,
        buttonLum,
        buttonAct
    }
}


export function useStakeF2() {
    const stakeAmount = useInput('', {teyp: 'number'})
    const stakeTime = useInput(30, {teyp: 'number'})

    const send = () => {
        const f2 = F2()
        const stakeAmountValue = stakeAmount.value
        const stakeAmountWei = BN(stakeAmountValue).mul(1e18).dp(0,1).toString(10)
        const stakeTimeValue = stakeTime.value * 86400
        return f2.methods.stake(stakeAmountWei, stakeTimeValue)
    }

    const {
        coins,
        sender
    } = useMemo(()=> {
        const sender = F2()._address
        const tokenAll = TokenAll()
        return {
            coins:[[...tokenAll.map.FY, 10000, true]],
            sender
        }
    }, [])

    const approve = {
        coins,
        sender
    }
    const button = useButton('Stake', {
        send,
        approve
    })
    
    return {
        stakeAmount,
        stakeTime,
        button
    }
}

export function usePurchase() {
    const purchaseAmount = useInput('', {teyp: 'number'})

    const {
        coins,
        sender
    } = useMemo(() => {
        const sender = F1()._address
        const tokenAll = TokenAll()
        return {
            coins:[[...tokenAll.map.USDT, 10000, true]],
            sender
        }
    }, [])


    const send = () => {
        const f1 = F1()
        const purchaseAmountValue = purchaseAmount.value
        const purchaseAmountWei = BN(purchaseAmountValue).mul(1e18).dp(0,1).toString(10)
        return f1.methods.purchaseLUM(purchaseAmountWei)
    }
    
    const approve = {
        coins,
        sender,
    }
    const button = useButton('Purchase LMU', {
        send: () => send(1),
        approve
    })

    return {
        purchaseAmount,
        button
    }

}

export function useClaimF1() {
    const {account} = useWeb3()
    const button = useButton('Claim', {
        send: () => {
            const f1 = F1()
            return f1.methods.claimFor(account)
        }
    })
    return button
}

export function useClaimF2() {
    const {account} = useWeb3()
    const button = useButton(
        'Claim',
        {
            send: () => {
                // console.log('account', account, "gsdgd")
                const f2 = F2()
                return f2.methods.claimFor(account)
            }
        }
    )
    return button
}

